.shopContainer {
  display: flex;
  flex-direction: column; /* Vertikale Anordnung */
  gap: 20px; /* Abstand zwischen den Elementen */
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif !important;
}

.headline{
  text-align: center;
  margin-top: 5rem;
}

.cartButton {
  background: linear-gradient(135deg, #00c853, #3dafac);
  color: #fff;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: bold;
  margin: 1rem auto; /* Zentriert das Element */
  width: 20rem;
  display: block; /* Stellt sicher, dass es als Block-Element behandelt wird */
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cartButton:hover {
  background: linear-gradient(135deg, #3dafac, #00c853);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px); /* Leichtes Anheben */
}

input[type="text"] {
  width: 100%;
  padding: 10px;
  margin-top: 8px;
  border-radius: 5px;
  border: 1px solid #ddd;
}
