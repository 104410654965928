.groovecast-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  background-color: black;
  padding: 30px;
  justify-content: center;
  align-items: center;
}

.groovecast-item {
  position: relative;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  text-align: center;
  overflow: hidden; /* Verhindert, dass das gedrehte Bild "ausbricht" */

}

.groovecast-item:hover {
  transform: rotate(380deg) scale(1.1); /* Minimale Verkleinerung */
}

.groovecast-cover {
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.6s ease-in-out;
}

.cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.groovecast-title {
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  color:white;
}

.groove-title {
  font-size: 3.5rem;
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
  text-align: center;
}


/* Mobile Anpassung für kleinere Bilder */
@media (max-width: 768px) {
  .groovecast-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 Spalten */
    gap: 15px;
    padding: 15px;
  }

  .groovecast-cover {
    width: 100px; /* Kleinere Cover */
    height: 100px;
  }

  .cover-image {
    width: 100%;
    height: 100%;
  }

  .groovecast-title {
    font-size: 12px; /* Kleinere Schrift */
  }
}