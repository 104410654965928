.section-title {
  font-size: 3rem;
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
  padding: 10px 20px; /* Add some padding around the text */
}

.section{
  margin-top: 9rem;
  @media screen and (max-width: 768px) {
    margin-top: 5.5rem;
  }
}