.residents-container {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
  }
  
  .resident-card {
    position: relative;
    width: 20rem;
    height: 20rem;
    border-radius: 15px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
  }
  
  .resident-card:hover {
    transform: scale(1.05);
  }
  
  .resident-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
  }
  
  .hover-overlay {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .resident-card:hover .hover-overlay {
    opacity: 1;
  }
  
  .book-button {
    padding: 12px 24px;
    font-size: 18px;
    font-weight: bold;
    background: linear-gradient(135deg, #ff416c, #ff4b2b);
    color: white;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    box-shadow: 0 4px 15px rgba(255, 65, 108, 0.4);
  }
  
  .book-button:hover {
    transform: scale(1.1);
  }
  
  .resident-name {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-top: 10px;
    color: #fff;
  }
  