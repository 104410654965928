.groove-cast-container {
    padding: 2rem;
    background: linear-gradient(45deg, #ff00ff, #00ffff);
    border-radius: 10px;
    align-self: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 80%;
    margin: 0 auto; /* Zentriert horizontal */

  }
  
  .groove-title {
    font-size: 3rem;
    font-weight: bold;
    color: #fff;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    margin-bottom: 10px;
  }
  
  .groove-scroll {
    display: flex;
    gap: 15px;
    overflow-x: auto;
    scrollbar-width: none;
    padding: 10px;
    scroll-snap-type: x mandatory;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  
  .groove-scroll::-webkit-scrollbar {
    display: none;
  }
  
  .groove-item {
    flex: 0 0 auto;
    padding: 2rem;
    width: 20rem;
    height: 20rem;
    border-radius: 15px;
    overflow: hidden;
    transform: rotate(-2deg);
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    scroll-snap-align: center;
  }
  
  .groove-item:nth-child(even) {
    transform: rotate(2deg);
  }
  
  .groove-item:hover {
    transform: scale(1.1) rotate(0deg);
  }
  
  .groove-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: contrast(1.2) saturate(1.3);
    transition: filter 0.3s ease-in-out;
  }
  
  .groove-item:hover .groove-image {
    filter: contrast(1.5) saturate(1.5);
  }
  
  @media (max-width: 768px) {
    .groovecast-grid {
      grid-template-columns: repeat(3, 1fr); /* 3 Spalten */
      gap: 15px;
      padding: 15px;
    }
  
    .groovecast-cover {
      width: 100px; /* Kleinere Cover */
      height: 100px;
    }
  
    .cover-image {
      width: 100%;
      height: 100%;
    }
  
    .groovecast-title {
      font-size: 12px; /* Kleinere Schrift */
    }
  }