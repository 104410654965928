
.footer {
    background-color: #000;
    color: #fff;
    padding: 2rem 1rem;
    text-align: center;
    font-family: "Arial", sans-serif;
    box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.3);
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
  
  .footer p {
    margin: 0;
    font-size: 1rem;
    color: #ddd;
  }
  
  .footer-links {
    display: flex;
    gap: 2rem;
  }
  
  .footer-links a {
    color: #ffc300; /* Akzentfarbe */
    text-decoration: none;
    font-weight: bold;
    font-size: 1rem;
    transition: color 0.3s ease;
  }
  
  .footer-links a:hover {
    color: #daaf00; /* Leicht dunkleres Gelb */
  }
  
  @media (max-width: 768px) {
    .footer-links {
      flex-direction: column;
      gap: 1rem;
    }
  }
  