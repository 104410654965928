.cartContainer {
    padding: 1rem;
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .cartItems {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .orderButton{
    background: linear-gradient(135deg, #00c853, #3dafac);
    color: #fff;
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: bold;
    margin: 1rem auto; /* Zentriert das Element */
    width: 20rem;
    display: block; /* Stellt sicher, dass es als Block-Element behandelt wird */
    text-align: center;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .paypalContainer{
    display: flex;
    flex-direction: column; /* Vertikale Anordnung */
    padding: 2rem;
    margin: 1rem auto; /* Zentriert das Element */
  }
  
  .cartItem {
    display: flex;
    align-items: center;
    color: #000;
    background-color: #fff;
    border-radius: 5px;
    padding: 1rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    border: 2px solid black;

  }
  
  .cartItem:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    border: 2px solid red;
  }

  .cartItemImage {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 1rem;
  }
  
  .cartItemDetails {
    text-align: left;
  }
  
  .cartItemName {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .totalAmount {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .addressForm input {
    width: 100%;
    margin-bottom: 10px;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .checkoutButton {
    background-color: #00c853;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 1rem;
  }
  
  .checkoutButton:hover {
    background-color: #00a040;
  }
  
  .closeButton {
    background-color: #ccc;
    color: #000;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 10px;
  }
  
  .closeButton:hover {
    background-color: #bbb;
  }
  