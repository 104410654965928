.toast {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background: linear-gradient(135deg, #3dafac, #00c853);
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    font-size: 1rem;
    animation: fadeInOut 5s ease forwards;
    z-index: 1000;
    font-family: Arial, Helvetica, sans-serif !important;

  }
  
  @keyframes fadeInOut {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    10% {
      opacity: 1;
      transform: translateY(0);
    }
    90% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(10px);
    }
  }
  