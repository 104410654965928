.productContainer {
    background: #f9f9f9;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    margin-bottom: 2rem;
    text-align: center;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    max-width: 400px;
    margin: 0 auto;
  }
  
  .productContainer img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 1rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .productContainer h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #3d3d3d;
  }
  
  .productContainer p {
    font-size: 1rem;
    color: #6e6e6e;
    margin-bottom: 1rem;
  }
  
  .productContainer .addContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 1rem;
    background: linear-gradient(135deg, #f1f1f1, #e5e5e5);
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  .addContainer select {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    background-color: #fff;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
  }
  
  .addContainer select:hover {
    border-color: #3dafac;
    box-shadow: 0 0 5px #3dafac;
  }
  
  .addContainer button {
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background: linear-gradient(135deg, #3dafac, #00c853);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .addContainer button:hover {
    background: linear-gradient(135deg, #00c853, #3dafac);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
    transform: scale(1.05);
  }
  