/* Main container styling */
.detailContainer {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%; /* Prevents overflow */
  box-sizing: border-box; /* Includes padding in width calculation */
}

.detailImage {
  width: 20rem;
  height: 20rem;
  margin-bottom: 1rem;
}

.detailInfoContainer {
  padding: 1.5rem;
  max-width: 90vw; /* Ensures content is within viewport width */
  box-sizing: border-box;
}

.detailTitle {
  font-size: 2.5rem;
  line-height: 1.2;
}

.detailDescription {
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 1rem;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  width: 100%;
}

.infoContainer {
  font-family: Arial, Helvetica, sans-serif !important;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  gap: 2.5rem;
}

.infoDetail {
  font-size: 1rem;
  color: #ddd;
}

#venue {
  font-weight: bold;
}

#date {
  font-style: italic;
  color: #aaa;
}

.detailBuyButton {
  font-size: 1rem;
  padding: 10px 20px;
  color: #fff;
  background: linear-gradient(135deg, #ff00ff, #00ffff, #ff6600);
  background-size: 200% 200%;
  border: 2px solid #00ffff;
  border-radius: 8px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 0 10px #00ffff, 0 0 20px #ff00ff, 0 0 30px #ff6600;
  animation: neonGlow 3s infinite linear, gradientShift 5s infinite alternate;
  max-width: 90%; /* Keeps button within viewport width */
}

.detailBuyButton:hover {
  box-shadow: 0 0 20px #00ffff, 0 0 30px #ff00ff, 0 0 40px #ff6600;
  transform: scale(1.05);
}

.instagramLinkButton {
  margin-bottom: 1rem;
  font-size: 1rem;
  padding: 10px 20px;
  color: #fff;
  background: linear-gradient(45deg, #f09433, #e6683c, #dc2743, #cc2366, #bc1888);
  background-size: 200% 200%;
  border: none;
  border-radius: 50px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  animation: gradientShiftInstagram 5s infinite alternate;
  max-width: 90%;
}

@media only screen and (max-width: 768px) {
  .detailTitle {
      font-size: 2rem;
  }
  .detailDescription {
      font-size: 0.9rem;
  }
  .detailBuyButton, .instagramLinkButton {
      font-size: 0.9rem;
      padding: 8px 16px;
  }
}
