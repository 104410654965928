/* src/fonts.css */
@font-face {
  font-family: 'Ashing';
  src: url('/public/fonts/Ahsing-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

body {
  letter-spacing: 0.1rem;
  font-family: 'Ashing', sans-serif;
  color: white;
}
