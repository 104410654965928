.product-grid {
    font-family: Arial, sans-serif;
  }
  
  .product-card {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 1.5rem;
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .product-image {
    width: 200px;
    height: 200px;
    padding: 1rem;
    object-fit: cover;
    border-right: 1px solid #eee;
  }
  
  .product-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
  }
  
  .product-name {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .product-description {
    font-size: 1rem;
    color: #666;
    margin-bottom: 0.5rem;
  }
  
  .product-price {
    font-size: 1.2rem;
    font-weight: bold;
    color: #007bff;
    margin-bottom: 1rem;
  }
  
  .add-product {
    background-color: #007bff;
    color: white;
    border: none;
    width: 20rem;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .add-product:hover {
    background-color: #0056b3;
  }
  
  .selected-product {
    padding: 1rem;
    border-radius: 8px;
    background-color: #9159be;
    margin-bottom: 2rem;
  }
  
  .selected-product ul {
    list-style: none;
    padding: 0;
  }
  
  .selected-product li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }
  
  .selected-product input {
    padding: 0.3rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-top: 0.5rem;
  }
  
  .reservation-form {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
  }
  
  .reservation-form input {
    margin-bottom: 1rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .remove-product {
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .reservation-form button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 0.7rem;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .reservation-form button:hover {
    background-color: #0056b3;
  }
  

    /* Responsive Design */
    @media (max-width: 768px) {
        .product-card {
          flex-direction: column;
          text-align: center;
        }
      
        .product-image {
          width: 95%;
          height: 200px;
          border-right: none;
          align-self: center;
          border-bottom: 1px solid #eee;
        }
      
        .add-product {
          align-self: center;
          margin: 1rem;
          width: 90%;
          padding: 1rem;
        }
      }
      